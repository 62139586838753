import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Grid from "@material-ui/core/Grid";

import Certified from "../../assets/home/toronto-plumber.svg";
import Design from "../../assets/home/toronto-plumbers.svg";
import styles from "../../jss/productStyle.js";

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
  },
  section2: {
    padding: "70px 0",
  },
  paragraph2: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "poppins",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
    "@media screen and (min-width:1920px)": {
      marginTop: "10px",
    },
  },
  imageContainer: {
    padding: "60px 60px 0px 60px",
    marginTop: "40px",
    "@media screen and (max-width:500px)": {
      padding: "0px 80px 0px 80px",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "1",
    },
  },

  orderContainer: {
    "@media screen and (max-width:500px)": {
      order: "2",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "2",
    },
  },
});

export default function ProductSection() {
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item lg={3} xs={10} sm={5} md={5}>
          <div align="center" className={classes.imageContainer}>
            <Certified alt="toronto-plumber" height="100%" width="100%" />
          </div>
        </Grid>

        <Grid item xs={10} sm={10} md={10} lg={7}>
          <h2 className={classes.title} style={{ fontWeight: "bold" }}>
            Certified & Insured - Plumber Toronto
          </h2>
          <p className={classes.paragraph2}>
            GoPro Plumbing are your Toronto plumbers whenever you need us.
            Offering both residential and commercial plumbing services, our
            dedicated team can help.
          </p>
          <p className={classes.paragraph2}>
            Your plumbing needs require top plumbers to carry out maintenance
            and repairs for you. Whether you want to schedule routine
            maintenance or you're looking for someone to perform some emergency
            repairs, our team at GoPro Plumbing is here for you 24/7. When you
            need plumbers in Toronto, simply give us a call and we'll be there
            when you need us.
          </p>
          <p className={classes.paragraph2}>
            You can guarantee a job well done when you choose us to take care of
            your plumbing. We have been serving the people of Toronto for more
            than 15 years since the company was founded in 2005. Our satisfied
            customers love our dedicated service and attention to detail, as
            well as our high-quality workmanship. Our team is committed to
            delivering the very best results for all of our clients, with a
            focus on renovations, commercial plumbing, and new builds. As the
            preferred plumber for many across the GTA, we're proud to serve our
            community.
          </p>
        </Grid>

        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={7}
          className={classes.orderContainer}
        >
          <h2 className={classes.title}> Design & Build</h2>
          <p className={classes.paragraph2}>
            You can expect us to go above and beyond to answer the call of duty
            whenever our plumbing services are required. Our team is always tidy
            and respectful and never leaves a job until both we and our
            customers are completely satisfied. You can trust us to always
            deliver on our core values of integrity, support, respect, safety,
            and leadership.
          </p>
          <p className={classes.paragraph2}>
            Call us now to find out more about our services and what we can do
            for you — <a href="tel:+16473709868" style={{ color: "black" }}>647-370-9868.</a>
          </p>
          <p>
            Get a free quote from GoPro Plumbing and ask about our Toronto
            plumbing services.
          </p>
        </Grid>
        <Grid item lg={3} xs={10} sm={5} md={5}>
          <div align="center" className={classes.imageContainer}>
            <Design alt="toronto-plumbers" height="100%" width="100%" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
