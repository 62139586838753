import React from "react";
// nodejs library that concatenates classes

// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";
import styles from "../../jss/landing-webdesign.js";

import Riser from "../../assets/home/plumbers-toronto-2.svg";
import Water from "../../assets/home/plumbers-toronto.svg";
import Kitec from "../../assets/home/plumbers-toronto-3.svg";
import Construction from "../../assets/home/toronto-plumbers-13.svg";

import Button from "@material-ui/core/Button";

const useStyles = makeStyles(styles);
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.section}>
        <Grid container justify="center">
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              COMMERCIAL Plumbing Services
            </h2>
          </Grid>
          <Grid item lg={5} xs={10} sm={10} md={7}>
            <p className={classes.paragraph}>
              We have worked with businesses in Toronto for a number of years.
              In particular, we are experienced in commercial construction.
              Toronto businesses also rely on our plumbing maintenance and our
              24/7 emergency services to help them stay operational and quickly
              react to any problems. Commercial plumbing is often more likely to
              experience issues due to higher use, but our services can provide
              for all of your requirements whenever you have a problem.
            </p>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
          align="center"
        >
          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Construction
                    alt="toronto-plumbers"
                    height="128px"
                    width="128px"
                  />
                </div>
              </Grid>
              <Grid item lg={11} md={10} sm={11} xs={11}>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Commercial Construction
                  </h3>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <p className={classes.paragraph}>
                    Our team of plumbers in toronto can help you build your next
                    commercial project. Learn more about our commercial new
                    construction services.
                  </p>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <div align="center">
                    <Link
                      to="/commercial-construction"
                      href="https://goproplumbing.ca/commercial-construction"
                    >
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "10px",
                        }}
                        variant="contained"
                      >
                        Commercial New Construction
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Riser alt="toronto-plumbers" height="128px" width="128px" />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Riser Replacements
                  </h3>
                </Grid>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <p className={classes.paragraph}>
                    Our team has extensive experience in the turnkey replacement
                    of closed-hoop hydronic riser systems and associated header
                    piping.
                  </p>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <div align="center">
                    <Link
                      to="/riser-replacement-commercial"
                      href="https://goproplumbing.ca/riser-replacement-commercial"
                    >
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "10px",
                        }}
                        variant="contained"
                      >
                        Riser Replacements
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Water alt="toronto-plumbers" height="128px" width="128px" />
                </div>
              </Grid>
              <Grid item lg={11} md={10} sm={11} xs={11}>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Watermain Replacement
                  </h3>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <p className={classes.paragraph}>
                    Our team is certified to replace old deteriorating water
                    mains installed in 1990. Let us help you provide clean and
                    safe water to your commercial space.
                  </p>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <div align="center">
                    <Link
                      to="/watermain-replacement-commercial"
                      href="https://goproplumbing.ca/watermain-replacement-commercial"
                    >
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "10px",
                        }}
                        variant="contained"
                      >
                        Watermain Replacement
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Kitec alt="toronto-plumbers" height="128px" width="128px" />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={6} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Kitec Removal
                  </h3>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <p className={classes.paragraph}>
                    Kitec plumbing and fittings can prematurely fail. Our team
                    will help prolong their lifespan, increase durability, and
                    prevent bursts by replacing them with copper.
                  </p>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <div align="center">
                    <Link
                      to="/kitec-removal-commercial"
                      href="https://goproplumbing.ca/kitec-removal-commercial"
                    >
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "10px",
                        }}
                        variant="contained"
                      >
                        Kitec Removal
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
