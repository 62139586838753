import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";
import styles from "../../jss/landing-webdesign.js";

import Home from "../../assets/home/toronto-plumbers-2.svg";
import Faucet from "../../assets/home/toronto-plumbers-3.svg";
import Drain from "../../assets/home/toronto-plumbers-4.svg";
import Valve from "../../assets/home/toronto-plumbers-5.svg";

import Button from "@material-ui/core/Button";

const useStyles = makeStyles({ ...styles });
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div style={{ padding: "60px 0px 0px 0px" }}>
      <div className={classNames(classes.section, classes.sectionDark)}>
        <Grid container justify="center">
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              Our Plumbing Services
            </h2>
          </Grid>
          <Grid item lg={5} xs={10} sm={10} md={7}>
            <p className={classes.paragraph}>
              At GoPro Plumbing, our Toronto plumbers are able to deliver a
              range of different plumbing services. We work with both businesses
              and homeowners and renters to ensure they get the plumbing
              services that they need. No matter what services you need, we
              treat each job with the utmost importance. Our experience,
              expertise, and dedication allow us to approach every project with
              the required commitment.
            </p>
          </Grid>
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              Residential Plumbing Services
            </h2>
          </Grid>
          <Grid item lg={5} xs={10} sm={10} md={7}>
            <p className={classes.paragraph}>
              Our residential services help homeowners and tenants to be
              comfortable in their homes. We can take care of jobs both big and
              small, from planning plumbing for new build construction to
              upgrading fixtures and troubleshooting any issues you experience.
              Whether you have a leak or are looking for drain cleaning and
              inspection, we can help.
            </p>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
          align="center"
        >
          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Home alt="toronto-plumbers" height="128px" width="128px" />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    New Construction
                  </h3>
                </Grid>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <p className={classes.paragraph}>
                    We have extensive experience with new construction. Contact
                    us and we will help you build your dream home.
                  </p>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <div align="center">
                    <Link
                      to="/licensed-plumber"
                      href="https://goproplumbing.ca/licensed-plumber"
                    >
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "10px",
                        }}
                        variant="contained"
                      >
                        New Construction
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Faucet alt="toronto-plumbers" height="128px" width="128px" />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Fixture Upgrades
                  </h3>
                </Grid>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <p className={classes.paragraph}>
                    Our team will not only help you upgrade your fixtures, but
                    we will upgrade them to the latest and most efficient
                    styles.
                  </p>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <div align="center">
                    <Link
                      to="/plumbing-services"
                      href="https://goproplumbing.ca/plumbing-services"
                    >
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "10px",
                        }}
                        variant="contained"
                      >
                        Fixture Upgrades
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Valve alt="toronto-plumbers" height="128px" width="128px" />
                </div>
              </Grid>
              <Grid item lg={11} md={10} sm={11} xs={11}>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Backwater Valve
                  </h3>
                </Grid>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <p className={classes.paragraph}>
                    Our team will help you prevent floods and leaks in your
                    property. Choose us to supply and install backwater valves
                    in your home.
                  </p>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <div align="center">
                    <Link
                      to="/backwater-valve-plumbers-toronto"
                      href="https://goproplumbing.ca/backwater-valve-plumbers-toronto"
                    >
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "10px",
                        }}
                        variant="contained"
                      >
                        Backwater Valve
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Drain alt="toronto-plumbers" height="128px" width="128px" />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Drain Cleaning & Inspection
                  </h3>
                </Grid>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <p className={classes.paragraph}>
                    With camera inspections and drain snakes, our toronto
                    plumbers will thoroughly inspect, clean, and unclog your
                    piping system.
                  </p>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <div align="center">
                    <Link
                      to="/after-hour-plumber"
                      href="https://goproplumbing.ca/after-hour-plumber"
                    >
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "10px",
                        }}
                        variant="contained"
                      >
                        Drain Cleaning
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
