import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";
import styles from "../../jss/landing-webdesign.js";

import Button from "@material-ui/core/Button";

const useStyles = makeStyles(styles);
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.section2, classes.sectionDark)}>
      <div>
        <Grid container>
          <Grid item lg={6} xs={12} sm={10} md={7}>
            <div className={classes.containerLeak}>
              <Grid item lg={10} xs={12} sm={10} md={12}>
                <h2 className={classes.title3}>LEAK DETECTION</h2>
              </Grid>
              <Grid item lg={10} xs={10} sm={10} md={12}>
                <p className={classes.paragraph}>
                  At GoPro Plumbing, we are proud to offer PhynPlus technology
                  to our clients. PhynPlus is a smart water monitoring system
                  that uses high-definition pressure wave analysis to detect
                  leaks, alert property owners, provide water usage statistics,
                  and mitigate costly damage through automatic water shutoff. It
                  is the perfect device for both commercial and residential
                  properties who want a quick and efficient way to detect and
                  avoid plumbing problems.
                </p>
                <div className={classes.button}>
                  <Link
                    to="/leak-detection-phyn"
                    href="https://goproplumbing.ca/leak-detection-phyn"
                  >
                    <Button
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        marginTop: "10px",
                      }}
                      variant="contained"
                    >
                      Leak Detection
                    </Button>
                  </Link>
                </div>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={5} xs={12} sm={10} md={7}>
            <Grid
              container
              justify="flex-start"
              direction="column"
              alignItem="right"
            >
              <Grid item lg={12} xs={12} sm={10} md={12}>
                <h2 className={classes.title4}>Frozen Pipe Detection</h2>
              </Grid>
              <Grid item lg={8} xs={10} sm={10} md={12}>
                <p className={classes.paragraph}>
                  One of the most common issues plumbers in Toronto and the GTA
                  face are frozen pipes. PhynPlus alerts you the second ice
                  crystals start forming in your piping system, giving you
                  enough time to call the chosen plumbers in Toronto and avoid
                  more complicated issues like burst pipes.
                </p>
              </Grid>

              <Grid item lg={12} xs={12} sm={10} md={12}>
                <h2 className={classes.title5}>Leak Alters</h2>
              </Grid>
              <Grid item lg={8} xs={10} sm={10} md={12}>
                <p className={classes.paragraph}>
                  PhynPlus comes with a mobile application called PhynApp. With
                  its help, property owners get alerts through SMS and
                  notifications if PhynPlus notices any leaks or issues
                  occurring. The device is also compatible with Amazon Alexa and
                  Google Assistant.
                </p>
              </Grid>

              <Grid item lg={12} xs={12} sm={10} md={12}>
                <h2 className={classes.title5}>Water use</h2>
              </Grid>
              <Grid item lg={8} xs={10} sm={10} md={12}>
                <p className={classes.paragraph}>
                  For those who want to cut their water spending, the PhynApp
                  keeps track of daily, weekly, and monthly water usage. This is
                  a great way to stay ahead of your monthly bills and raise
                  eco-consciousness in your close environment.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
