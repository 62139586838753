import React from "react";
// nodejs library that concatenates classes

// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";
import { Link } from "gatsby";
import Drain from "../../assets/home/toronto-plumbers-11.svg";
import Fixture from "../../assets/home/toronto-plumbers-9.svg";
import TroubleShoot from "../../assets/home/toronto-plumbers-10.svg";

import Button from "@material-ui/core/Button";

const useStyles = makeStyles(styles);
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.section}>
        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
          align="center"
          style={{
            paddingBottom: "80px",
            marginTop: "-50px",
          }}
        >
          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="right">
                  <Drain alt="toronto-plumbers" height="128px" width="128px" />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={6} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Drain Cleaning
                  </h3>
                </Grid>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <p className={classes.paragraph}>
                    Ensure your commercial space is well-maintained. With drain
                    snakes and camera inspections, our team of certified
                    plumbers will help you inspect and clean any clogged drains
                    for your property.
                  </p>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <div align="center">
                    <Link
                      to="/drain-cleaning-inspection-commercial"
                      href="https://goproplumbing.ca/drain-cleaning-inspection-commercial"
                    >
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "10px",
                        }}
                        variant="contained"
                      >
                        Drain Cleaning
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Fixture
                    alt="toronto-plumbers"
                    height="128px"
                    width="128px"
                  />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Fixture Upgrades
                  </h3>
                </Grid>
                <Grid item lg={7} md={10} sm={11} xs={11}>
                  <p className={classes.paragraph}>
                    Equip your commercial space with the latest plumbing
                    fixtures to save energy and reduce maintenance. Our team can
                    help you select and install the right fixtures for your
                    needs.
                  </p>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <div align="center">
                    <Link
                      to="/fixture-upgrades-commercial"
                      href="https://goproplumbing.ca/fixture-upgrades-commercial"
                    >
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "10px",
                        }}
                        variant="contained"
                      >
                        Fixture Upgrades
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <TroubleShoot
                    alt="toronto-plumbers"
                    height="128px"
                    width="128px"
                  />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Commercial Troubleshooting
                  </h3>
                </Grid>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <p className={classes.paragraph}>
                    Our certified team of plumbers can help you solve any major
                    or minor issues within your commercial property. We have
                    multiple years of experience keeping commercial spaces
                    operational.
                  </p>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <div align="center">
                    <Link
                      to="/commercial-plumbing-troubleshooting"
                      href="https://goproplumbing.ca/commercial-plumbing-troubleshooting"
                    >
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "10px",
                        }}
                        variant="contained"
                      >
                        Commercial Troubleshooting
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
