import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

import Wrench from "../../assets/home/toronto-plumbers-6.svg";
import Home from "../../assets/home/toronto-plumbers-7.svg";
import Pipe from "../../assets/home/toronto-plumbers-8.svg";
import { Link } from "gatsby";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(styles);
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div>
      <div className={classNames(classes.section, classes.sectionDark)}>
        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
          align="center"
          style={{
            paddingBottom: "80px",
            marginTop: "-50px",
          }}
        >
          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="right">
                  <Pipe alt="toronto-plumbers" height="128px" width="128px" />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={6} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Kitec Removal
                  </h3>
                </Grid>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <p className={classes.paragraph}>
                    Kitec plumbing and fittings can prematurely fail. Our team
                    will help prolong their lifespan, increase durability, and
                    prevent bursts by replacing them with copper.
                  </p>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <div align="center">
                    <Link
                      to="/kitec-removal"
                      href="https://goproplumbing.ca/kitec-removal"
                    >
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "10px",
                        }}
                        variant="contained"
                      >
                        Kitec Removal
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Home alt="toronto-plumbers" height="128px" width="128px" />
                </div>
              </Grid>
              <Grid item lg={11} md={10} sm={11} xs={11}>
                <Grid item lg={12} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Watermain Replacement
                  </h3>
                </Grid>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <p className={classes.paragraph}>
                    We are licensed and certified for replacing old and
                    deteriorating water mains installed even in 1990. Let us
                    help you provide clean and safe water to your home.
                  </p>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <div align="center">
                    <Link
                      to="/watermain-replacement"
                      href="https://goproplumbing.ca/watermain-replacement"
                    >
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "10px",
                        }}
                        variant="contained"
                      >
                        Watermain Replacement
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Wrench alt="toronto-plumbers" height="128px" width="128px" />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Residential Troubleshooting
                  </h3>
                </Grid>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <p className={classes.paragraph}>
                    Our experienced team of toronto plumbers can help you solve
                    and resolve any plumbing related issues. Let’s help you keep
                    that water running.
                  </p>
                </Grid>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <div align="center">
                    <Link
                      to="/24-hour-plumber"
                      href="https://goproplumbing.ca/24-hour-plumber"
                    >
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          marginTop: "10px",
                        }}
                        variant="contained"
                      >
                        Residential Troubleshooting
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item lg={5} xs={10} sm={10} md={7}>
            <p className={classes.paragraph}>
              GoPro Plumbing has been providing Toronto plumbing services to GTA
              residents for many years. Our satisfied customers rely on us to
              answer the call for routine plumbing and whenever they have a
              plumbing problem that needs repairs.
            </p>
            <p className={classes.paragraph}>
              When you need plumbing services for your home, get in touch with
              us at GoPro Plumbing to learn more about how we can help.
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
