import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Grid from "@material-ui/core/Grid";

import styles from "../../jss/productStyle.js";

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
  },
  section2: {
    padding: "70px 0",
  },
  paragraph2: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "poppins",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
    "@media screen and (min-width:1920px)": {
      marginTop: "10px",
    },
  },
  imageContainer: {
    padding: "60px 60px 0px 60px",
    marginTop: "40px",
    "@media screen and (max-width:500px)": {
      padding: "0px 60px 0px 60px",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "1",
    },
  },
  orderContainer: {
    "@media screen and (max-width:500px)": {
      order: "2",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "2",
    },
  },
});

export default function ProductSection() {
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        justify="center"
        alignContent="center"
        direction="column"
        alignItems="center"
        spacing={4}
        style={{ paddingBottom: "80px", paddingTop: "40px" }}
      >
        <Grid item xs={10} sm={10} md={10} lg={5}>
          <h2 className={classes.title}>Frequently Asked Questions</h2>
          <h2 className={classes.title5}>Do you offer emergency services?</h2>
          <p className={classes.paragraph2}>
            Our emergency services are available 24/7. Whenever you have a
            plumbing problem, just get in touch to ask for help and we will be
            there as quickly as we can.
          </p>

        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={5}>
          <h2 className={classes.title5}>
            Are your team of Toronto plumbers licensed and insured?
          </h2>
          <p className={classes.paragraph2}>
            Yes, we are fully licensed and insured to deliver plumbing services
            in Toronto. You can rely on us to provide safe services with a
            complete focus on maintaining a tidy workspace.
          </p>
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={5}>
          <h2 className={classes.title5}>
            How do I know if my plumbing has a leak?
          </h2>
          <p className={classes.paragraph2}>
            We use advanced and modern leak detection technology to detect any
            leaks in your plumbing. If you suspect there is a leak, we can pick
            up on it quickly and find the right solution. Some signs of a leak
            might include high water bills or noticeable signs of water where it
            shouldn't be.
          </p>
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={5}>
          <h2 className={classes.title5}>Which locations do you serve?</h2>
          <p className={classes.paragraph2}>
            Our plumbers provide services in Toronto, Oakville, Mississauga, and
            Brampton. Whether you need plumbing services in Toronto or one of
            our other locations, contact us today to ask about our services.
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
