import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";
import styles from "../../jss/landing-webdesign.js";

import Button from "@material-ui/core/Button";

const useStyles = makeStyles(styles);
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.section2, classes.sectionDark)}>
      <div>
        <Grid container>
          <Grid item lg={6} xs={12} sm={10} md={7}>
            <div className={classes.containerLeak}>
              <Grid item lg={10} xs={12} sm={10} md={12}>
                <h2 className={classes.title3}>WHY CHOOSE GOPRO PLUMBING</h2>
              </Grid>
              <Grid item lg={10} xs={10} sm={10} md={12}>
                <p className={classes.paragraph}>
                  GoPro Plumbing was founded in 2005 and has been providing
                  plumbing services to Toronto ever since. We specialize in home
                  renovations, as well as commercial and new build services,
                  with a team that enjoys a challenge. We aim to always provide
                  the very best workmanship, whatever the size of the job, and
                  we're completely dedicated to leaving our clients satisfied
                  with our work. Our team offers services ranging from small
                  repairs and routine maintenance to more demanding projects.
                </p>
                <div className={classes.button}>
                  <Link
                    to="/contact-us"
                    href="https://goproplumbing.ca/contact-us"
                  >
                    <Button
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        marginTop: "10px",
                      }}
                      variant="contained"
                    >
                      GET AN ESTIMATE
                    </Button>
                  </Link>
                </div>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={5} xs={12} sm={10} md={7}>
            <Grid
              container
              justify="flex-start"
              direction="column"
              alignItem="right"
            >
              <Grid item lg={12} xs={12} sm={10} md={12}>
                <h2 className={classes.title4}>Get Started Today</h2>
              </Grid>
              <Grid item lg={8} xs={10} sm={10} md={12}>
                <p className={classes.paragraph}>
                  Contact us to get a free quote for our plumbing services. Call
                  <a href="tel:+16473709868" /> 647-370-9868 <a />to speak to a friendly member of our team about
                  your Toronto plumbing requirements and how we can help you to
                  maintain your residential or commercial plumbing.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
